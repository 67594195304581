@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
/* Variables */
:root {
  --portfolio-image-background: #f2f0ee;
  --logo-color: #58595b;
  --hover-on-icons: #2e2e2e;
  --text-link: 1rem;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: #e45447;
  border: 0.083rem solid #f2f0ee;
  border-radius: 0.5rem;
}

/* Reset */
*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0 solid;
}

html {
  line-height: 1.5;
  overflow-x: hidden;
  background: rgb(242, 240, 238);
}

a {
  text-decoration: none;
  color: var(--logo-color);
}

.text-link {
  font-size: var(--text-link);
}

ul {
  list-style-type: none;
}

.hidden {
  display: none !important;
}

/* Body */
.App {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  background: rgb(242, 240, 238);
  padding: 4rem 0;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.icon {
  color: var(--logo-color);
}

.icon-menu:hover {
  color: var(--hover-on-icons);
}

.orange-hover {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  transition: all 0.2s;
}

.orange-hover:hover {
  background-color: #e45447;
  color: #f2f0ee;
  box-shadow: 0 1rem 2rem rgba(43, 41, 41, 0.4);
  transform: rotate(-5deg) scale(1.1);
}

/* Rest */
.main {
  max-width: 65rem;
  margin: 0 auto;
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  /* responsiveness */
  display: flex;
  flex-direction: row;
}

.main-main {
  margin-left: 75px;
  width: 100%;
}

/* Logo */
.logo {
  text-decoration: none;
  color: var(--logo-color);
}

.logo span {
  color: #ff9379;
}

.mobile-logo {
  display: none;
}

/* Media */
.media {
  margin-top: 16rem;
  display: flex;
  flex-direction: column;
}

.media a {
  margin-bottom: 15px;
}

.portfolio-image {
  width: 350px;
  border-radius: 50%;
  background: var(--portfolio-image-background);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 55rem;
  margin: 2.5rem auto 0;
}

.footer-logo {
  width: 25%;
}

.footer-links {
  width: 50%;
}

.footer-links .nav-links ul {
  justify-content: center;
}

.rights-reserved {
  width: 25%;
  padding: 0.25rem 0.5rem;
  font-size: 0.6rem;
  text-align: center;
  text-transform: uppercase;
}

.end-here {
  max-width: 55rem;
  display: flex;
  margin: 1.25rem auto;
  height: 0.75rem;
}

.end-box {
  width: 25%;
  height: 100%;
}

.end-here-1 {
  background: #e45447;
}

.end-here-2 {
  background: #e29d51;
}

.end-here-3 {
  background: #d8c7b8;
}

.end-here-4 {
  background: #607393;
}

/* Navbar Begin */
.navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.menu-burger-icon {
  display: none;
}

.nav-links ul {
  display: flex;
}

.nav-links ul li:not(:first-child) {
  margin-left: 1.3rem;
}

.nav-links ul li a span {
  margin-left: 0.4rem;
}

.email i {
  font-size: 1rem;
  margin-right: 10px;
}

/* Navbar End */
.home {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home-left {
  flex: 1 1 0%;
}

.home-right {
  flex: 1 1 0%;
  text-align: left;
}

.cartoon-text {
  width: 80px;
  height: 18px;
  position: relative;
  padding: 10px;
  border-radius: 3px;
  margin: 25px;
  min-height: 50px;
  color: #f2f0ee;
  border: 1px solid #2b2929;
  background-color: #2b2929;
  text-shadow: 0 0 1px #000;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.cartoon-text::before {
  content: "";
  position: absolute;
  right: 7px;
  bottom: -10px;
  border-top: 10px solid #2b2929;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  width: 0;
  height: 0;
}

.header-1 {
  font-size: 3rem;
  line-height: 1;
  font-weight: 800;
  color: #58595b;
  margin-bottom: 1rem;
}

.name-spans {
  display: inline-block;
}

.job-role {
  color: #58595b;
  font-weight: 700;
  font-size: 1.35rem;
}

.job-summary {
  width: 16rem;
  margin-top: 3rem;
  color: #58595b;
  font-size: 0.875rem;
}

.talk-bubble {
  display: inline-block;
  margin-top: 3rem;
  font-family: "Nunito", sans-serif;
}

.lets-talk {
  font-size: 1.2rem;
  line-height: 1;
  display: inline-block;
  position: relative;
  right: -55px;
  color: #f2f0ee;
  background: #dc4537;
  padding: 1rem 0.7rem;
  border-radius: 50% 50%;
  z-index: 3;
}
.lets-talk::before {
  content: "";
  position: absolute;
  top: -0.8rem;
  left: -0.8rem;
  width: calc(100% + 1.6rem);
  height: calc(100% + 1.6rem);
  background: #dc4537;
  border: 0.8rem solid rgba(228, 145, 137, 0.5);
  border-radius: inherit;
  opacity: 1;
  z-index: -1;
}
.lets-talk:hover::before {
  animation: borderAnimation 1s;
}

@keyframes borderAnimation {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.talk {
  margin-left: 14px;
}

#what-i-do {
  margin-top: 100px;
  width: 100%;
  color: #58595b;
}

.intro-what-i-do {
  padding-top: 28px;
}

.intro-what-i-do h1 {
  font-size: 2.2rem;
  line-height: 1;
  font-weight: 700;
}

.intro-what-i-do p {
  margin-top: 0.5rem;
  font-size: 1.125rem;
}

.divider {
  position: relative;
  display: inline-block;
  background: #e45447;
  padding: 0.3rem;
  border-radius: 50%;
}

.divider::after {
  content: "";
  position: absolute;
  width: 115px;
  padding: 0.12rem 0;
  background: #e45447;
  top: 0.15rem;
}

.main-what-i-do {
  border-radius: 0.5rem;
  margin-top: 40px;
  background: #f2f0ee;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.main-what-i-do h2 {
  font-size: 1.125rem;
  font-weight: 900;
  margin-bottom: 12px;
}

.main-what-i-do p {
  font-size: 1rem;
}

.left-what-i-do p:not(:last-of-type) {
  margin-bottom: 20px;
}

.left-what-i-do {
  text-align: center;
  padding: 1.25rem;
  margin: auto 2rem;
}

.see-work {
  display: inline-block;
  position: relative;
  margin-top: 2rem;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.see-work::before {
  content: "";
  position: absolute;
  background: rgb(228, 84, 71);
  opacity: 0.4;
  width: 0;
  padding: 1.5rem;
  left: -17px;
  top: -6.5px;
  border-radius: 25px;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.see-work:hover {
  color: white;
}
.see-work:hover::before {
  width: calc(100% + 34px);
  border-radius: 25px;
  opacity: 0.8;
}

.right-what-i-do {
  position: relative;
}
.right-what-i-do .images-right-side {
  width: 90%;
  position: absolute;
  bottom: 0;
}
.right-what-i-do .image-1 {
  z-index: 100;
  bottom: 50px;
}
.right-what-i-do .image-2 {
  bottom: 160px;
}
.right-what-i-do .image-3 {
  bottom: 30px;
}
.right-what-i-do .image-4 {
  bottom: 160px;
  right: -70px;
  z-index: 2;
}
.right-what-i-do .image-5 {
  bottom: 160px;
  left: -100px;
}
.right-what-i-do .image-6 {
  bottom: 250px;
  right: -70px;
}
.right-what-i-do .image-7 {
  bottom: 250px;
  left: -70px;
}

#bio {
  margin: 8rem auto 1.5rem;
  width: 75%;
}

.bio-wrapper {
  font-size: 1rem;
  border-radius: 0.5rem;
  background: #f2f0ee;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  color: #58595b;
}

.bio-top {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  padding: 1rem;
}

.bio-top-left {
  width: 100%;
  border-radius: 0.5rem;
}

.bio-top-left img {
  width: 100%;
  border-radius: 0.5rem;
}

.bio-bottom {
  padding: 1rem;
}

.bio-bottom p:not(:first-of-type) {
  margin-top: 0.5rem;
}

.bio-bottom h2 {
  font-size: 1.3rem;
}

#my-works {
  margin-top: 8rem;
  color: #58595b;
}

.my-works-h1 {
  font-size: 2.25rem;
  line-height: 1;
}

.my-works-p {
  margin-top: 0.5rem;
  font-size: 1.125rem;
}

.web-apps-wrapper h1 {
  margin-top: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.web-apps {
  margin-top: 2rem;
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.web-app {
  position: relative;
  box-shadow: 0 0.332071px 2.21381px rgba(0, 0, 0, 0.0119), 0 0.798012px 5.32008px rgba(0, 0, 0, 0.0258), 0 1.50259px 10.0172px rgba(0, 0, 0, 0.0368), 0 2.68036px 17.869px rgba(0, 0, 0, 0.0479), 0 5.01331px 33.4221px rgba(0, 0, 0, 0.0621), 0 12px 80px rgba(0, 0, 0, 0.09);
  border-radius: 0.5rem;
  transition: all 0.2s;
}
.web-app:hover {
  transform: translateY(-5px);
}
.web-app:hover .overlay {
  height: 50%;
}

.web-app img {
  border-radius: 0.5rem;
  width: 100%;
  height: 150px;
  vertical-align: middle;
  border-style: solid;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}

.overlay {
  position: absolute;
  bottom: 0;
  margin: auto;
  width: 100%;
  background: #e45447;
  color: #f2f0ee;
  font-size: 1.5rem;
  overflow: hidden;
  height: 0%;
  opacity: 0.9;
  border-radius: 0 0 0.5rem 0.5rem;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resume-preview-wrapper {
  border-radius: 0.5rem;
  max-width: 75%;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 2.5rem;
  margin: 2.5rem auto;
  gap: 2rem;
}

.resume-preview-wrapper p {
  width: 60%;
  font-size: 1.125rem;
  font-weight: 700;
}

.resume-preview {
  display: inline-block;
  position: relative;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.resume-preview::before {
  content: "";
  position: absolute;
  background: rgb(228, 84, 71);
  opacity: 0.4;
  width: 0;
  padding: 1.5rem;
  left: -17px;
  top: -6.5px;
  border-radius: 25px;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.resume-preview:hover {
  color: white;
}
.resume-preview:hover::before {
  width: calc(100% + 34px);
  border-radius: 25px;
  opacity: 0.8;
}

.stay-connected {
  margin: 8rem auto 0;
  text-align: center;
  max-width: 20rem;
}

.stay-connected-1 {
  font-size: 1.125rem;
  font-weight: 700;
}

.stay-connected-2 {
  color: #718096;
  margin-top: 2rem;
  font-size: 0.875rem;
}

.stay-connected-3 {
  margin-top: 1rem;
}

.stay-connected-4 {
  margin: 1rem auto 0;
  display: flex;
  justify-content: space-evenly;
  max-width: 100%;
  align-items: center;
}

#my-works-page {
  color: #58595b;
}

#resume-page {
  color: #58595b;
}

.techs-i-use {
  margin-top: 1rem;
}

.techs-wrapper {
  padding: 1.25rem 2.5rem;
  background: #f2f0ee;
  margin-bottom: 2rem;
  border-radius: 0.5rem;
}

.techs-wrapper h2 {
  margin-bottom: 2.5rem;
  font-size: 1rem;
  font-weight: 200;
  font-style: italic;
}

.techs-wrapper-logo {
  margin: 0 25px 8px 0;
  text-align: center;
  display: inline-block;
  width: 4rem;
}

.tech-logo {
  width: 100%;
  height: 3rem;
  -o-object-fit: contain;
     object-fit: contain;
  filter: grayscale(100%);
  transition: all 0.2s;
}
.tech-logo:hover {
  transform: scale(1.2);
  filter: grayscale(0);
}

.resume-wrapper {
  margin-top: 1rem;
  background: #f2f0ee;
  width: 100%;
  padding: 1.3rem 2rem;
  border-radius: 0.5rem;
}
.resume-wrapper .job .custom-bullet {
  position: relative;
}
.resume-wrapper .job .custom-bullet:after {
  content: "";
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  background: #e45447;
  top: 4px;
  left: -18px;
  border-radius: 0.5rem;
}
.resume-wrapper .job .job-title {
  font-weight: bold;
  text-decoration: underline;
  font-size: 0.9rem;
  transition: all 0.3s;
  display: inline-block;
}
.resume-wrapper .job .job-title:hover {
  transform: translateY(-10%);
}
.resume-wrapper h2 {
  font-size: 1.2rem;
}
.resume-wrapper p {
  font-size: 0.8rem;
  font-style: italic;
}
.resume-wrapper ul {
  list-style-type: disc;
}
.resume-wrapper li {
  font-size: 1rem;
}

.resume-download {
  display: inline-block;
  position: relative;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.resume-download::before {
  content: "";
  position: absolute;
  background: rgb(228, 84, 71);
  opacity: 0.4;
  width: 0;
  padding: 1.5rem;
  left: -17px;
  top: -6.5px;
  border-radius: 25px;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.resume-download:hover {
  color: white;
}
.resume-download:hover::before {
  width: calc(100% + 34px);
  border-radius: 25px;
  opacity: 0.8;
}

.testimonials {
  margin-block: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.testimonial {
  position: relative;
  background: #f2f0ee;
  color: #58595b;
  padding: 2.2rem 1rem 1rem;
  font-size: 1.3rem;
  border-radius: 0.5rem;
  transition: all 0.3s;
}
.testimonial:hover {
  transform: translateY(-8px);
}
.testimonial p {
  font-size: 1rem;
  height: 190px;
  overflow: hidden;
  transition: all 0.3s;
}
.testimonial p:hover {
  overflow-y: auto;
}
.testimonial h2 {
  font-size: 1.2rem;
  margin-top: 0.5rem;
}
.testimonial h4 {
  font-size: 1rem;
  font-style: italic;
  font-weight: 200;
}
.testimonial .quote {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #e45447;
}
.testimonial .testimonial-image {
  width: 55px;
  height: 55px;
  position: absolute;
  top: -20px;
  left: 20px;
  border-radius: 50%;
  border: 0.25rem solid #f2f0ee;
  -o-object-fit: cover;
     object-fit: cover;
}

.enable-scroll {
  overflow: hidden;
}

.menu-links-wrapper {
  display: none;
}

.hide-on-large-screens {
  display: none;
}

@media only screen and (max-width: 1025px) {
  .main-what-i-do {
    grid-template-columns: repeat(1, 1fr);
  }
  .right-what-i-do {
    display: none;
  }
  .main {
    margin: 0 1rem;
  }
  #bio {
    width: 100%;
  }
}
@media only screen and (min-width: 766px) and (max-width: 1024px) {
  .main {
    margin: 0;
    border-radius: 0;
  }
  .footer {
    max-width: 45rem;
  }
  .end-here {
    max-width: 45rem;
  }
  .testimonials {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 765px) {
  html {
    background: white;
  }
  .side-main {
    display: none;
  }
  .App {
    padding: 0;
    background: white;
  }
  .main {
    margin: 0;
    border-radius: 0;
    padding: 1rem;
  }
  .footer {
    text-align: center;
    display: block;
    max-width: 90%;
  }
  .footer .footer-logo {
    width: 100%;
  }
  .footer .footer-links {
    width: 100%;
  }
  .footer .rights-reserved {
    margin-top: 1rem;
    width: 100%;
  }
  .end-here {
    max-width: 90%;
  }
  .home {
    width: 100%;
    display: block;
    text-align: center;
  }
  .home-left {
    position: relative;
  }
  .home-right {
    text-align: center;
    margin-top: 3rem;
  }
  .main-main {
    margin: 0;
  }
  .cartoon-text {
    margin-inline: auto;
  }
  .job-summary {
    margin-inline: auto;
    width: 100%;
  }
  .talk-bubble {
    position: absolute;
    bottom: -100px;
    right: 80px;
  }
  .bio-top {
    display: block;
  }
  .bio-top-left {
    margin-bottom: 1rem;
  }
  .bio-top-left img {
    width: 100%;
    height: 500px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top right;
       object-position: top right;
  }
  .web-apps {
    display: block;
  }
  .web-app {
    margin-bottom: 1.3rem;
    height: 200px;
  }
  .web-app img {
    height: 200px;
  }
  .resume-preview-wrapper {
    display: block;
  }
  .resume-preview-wrapper p {
    width: 100%;
    margin-bottom: 2rem;
  }
  .resume-preview {
    margin: auto;
  }
  .testimonials {
    grid-template-columns: repeat(1, 1fr);
  }
  .menu-burger-icon {
    color: #58595b;
    transition: all 0.2s;
  }
  .menu-burger-icon:hover, .menu-burger-icon:active {
    transform: translateY(-1px);
  }
  .menu-burger {
    position: relative;
  }
  .menu-links-wrapper {
    position: absolute;
    background: white;
    opacity: 1;
    z-index: 1000;
    top: 30px;
    right: 0;
    padding: 1rem;
    width: 60vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.3rem;
    border-radius: 0 0 0.5rem 0.5rem;
    box-shadow: 0 30px 50px -12px rgba(0, 0, 0, 0.25);
    transition: all 0.5s;
  }
  .menu-links-wrapper .close-icon {
    width: 35px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .menu-links-wrapper .close-icon:hover {
    transform: translateY(-3px);
  }
  .menu-links-wrapper .menu-link {
    color: #58595b;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 1.3rem;
    transition: all 0.3s;
  }
  .menu-links-wrapper .menu-link:hover, .menu-links-wrapper .menu-link:active {
    transform: translateY(-1.5px);
  }
  .menu-links-wrapper .pacman {
    position: absolute;
    top: 1.8rem;
    left: 1rem;
  }
  .navbar .nav-links ul {
    display: none;
  }
  .menu-burger-icon {
    display: inline-block;
    cursor: pointer;
  }
  .mobile-logo {
    display: inline-block;
  }
  .techs-wrapper-logo {
    margin: 0 0.05rem;
  }
  .resume-wrapper li {
    font-size: 0.8rem;
  }
  .techs-wrapper h2 {
    font-size: 0.9rem;
  }
  .testimonial p {
    font-size: 0.95rem;
  }
  .main-what-i-do p {
    font-size: 0.85rem;
  }
  .bio-wrapper {
    font-size: 0.85rem;
  }
  .my-works-h1 {
    font-size: 1.8rem;
  }
  .my-works-p {
    font-size: 0.9rem;
  }
  .web-apps-wrapper h1 {
    font-size: 1.2rem;
  }
  .intro-what-i-do h1 {
    font-size: 1.8rem;
  }
  #my-works {
    margin-top: 65px;
  }
  .testimonials .testimonial p {
    height: auto;
  }
  .hide-on-large-screens {
    display: block;
  }
}
.myClass-enter {
  transform: translateY(-25px);
}

.myClass-enter-active {
  transform: translateY(25px);
  transition: opacity 300ms ease-in, transform 300ms ease-in;
}

.myClass-exit {
  transform: translateY(0);
}

.myClass-exit-active {
  transform: translateY(-150%);
  transition: transform 300ms ease-in-out;
}/*# sourceMappingURL=index.css.map */